<template>
    <div class="mobile pt-5">
        <div class="container-fluid">
            <nav class="navbar fixed-top bg-white border-bottom ">
                <button class="ml-2" @click="togged = !togged">
                    <i class="fas fa-bars fa-1x" v-if="!togged"></i>
                    <i class="fas fa-x fa-1x" v-if="togged"></i>
                </button>
                <div class="text-right pr-3 justify-content-end">
                    <a @click="page_previous()" v-if="(route == 'mobile.form-support' && pagePrevious != undefined)">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </a>
                </div>
            </nav>
            <div class="sidebar-mobile" :class="{ 'togged': togged }">
                <template v-for="menuItem in listMenu">
                    <div :key="menuItem.title + 'title'"
                        v-if="Object.values(menuItem.status).some(ai => Object.values(menuItem.permissions).includes(ai))">
                        <template v-if="menuItem.position === 'header'">
                            <v-list-item v-if="!menuItem.children || !menuItem.children.length" :to="menuItem.url"
                                :key="menuItem.title" link>
                                <v-list-item-content @click="groupOpened = false">
                                    {{ menuItem.title }}
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-group v-else :key="menuItem.title" :group="menuItem.url" :value="menuItem.active">
                                <template #activator>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            {{ menuItem.title }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-for="subMenuItem in menuItem.children">
                                    <div :key="subMenuItem.title"
                                        v-if="Object.values(menuItem.status).some(ai => Object.values(subMenuItem.permission_children).includes(ai))">
                                        <v-list-item v-if="subMenuItem.url == 'admin/support-schedule-list'"
                                            class="pl-8" :key="subMenuItem.title"
                                            :to="menuItem.url + 'm/support-schedule-list'" link two-line>
                                            <v-list-item-content>
                                                {{ subMenuItem.title }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-else class="pl-8" :key="subMenuItem.title"
                                            :to="menuItem.url + subMenuItem.url" link two-line>
                                            <v-list-item-content>
                                                {{ subMenuItem.title }}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </template>
                            </v-list-group>
                        </template>
                    </div>
                </template>
                <hr>
                <span class="ml-8 mt-2 pb">
                    {{ userName }} ({{ userOccupationText }})
                    <Button @click="logout" class="ml-1"><i class="fas fa-sign-out text-success"></i></Button>
                </span>
                <hr>
            </div>
            <div class="mt-10">
                <slot />
            </div>
        </div>
        <!-- <div class="footer d-flex justify-content-center">
            &copy; Copyright
        </div> -->
    </div>
</template>

<style scoped lang="sass">
    .mt-30px
        margin-top: 30px
    .navbar
        line-height: 30px !important
    .sidebar-mobile
        background: white !important
        border-right: 1px solid #dee2e6
        z-index: 100
        margin-left: -300px
        position: absolute
        top: 45px
        transition: margin-left .3s ease-in-out, left .3s ease-in-out, margin-right .3s ease-in-out, right .3s ease-in-out
        height: auto
        &.togged
            margin-left: -12px
    .footer
        width: 100%
        line-height: 30px
</style>

<script>
import SideMenu from "../config/side-menu";
import { mapGetters } from "vuex";
import ApiService from "@/services/api/api.service";
import AuthRepository from "@/services/api/AuthRepository";
export default {
    name: "MobileLayout",
    components: {
        // SideBar,
        // AppHeader,
    },
    data() {
        return {
            route: null,
            togged: false,
            listMenu: [],
            isUserPermission: [],
        };
    },

    created() {
        this.listMenu = SideMenu.map(menuItem => {
            return {
                ...menuItem,
                active: !!this.$route.fullPath.includes(menuItem.url)
            }
        });

        if (this.user) {
            AuthRepository.checkAuth().then((res) => {
                const handlePermissions = res.data.user.user_sys_auth_div;
                const dataUserRole = handlePermissions.split(",");
                dataUserRole.forEach(element => {
                    this.isUserPermission.push(parseInt(element));
                });
                this.handleUserPermissions(this.listMenu);
            });
        }
    },
    watch: {
        $route(to) {
            if (to && to.fullPath && this.listMenu) {
                this.listMenu = this.listMenu.map(menuItem => {
                    return {
                        ...menuItem,
                        active: !!to.fullPath.includes(menuItem.url)
                    }
                })
            }
            this.$forceUpdate()
        },
        "$route.name"() {
            this.route = this.$route.name;
        }
    },
    mounted() {
        this.route = this.$route.name;
    },
    computed: {
        title() {
            return this.$route.meta.title;
        },
        explain() {
            return this.$route.meta.explain;
        },
        userName() {
            return this.user && this.user.user_name ? this.user.user_name : "User Name"
        },
        userOccupationText() {
            return this.user && this.user.user_occupation_text ? this.user.user_occupation_text : ""
        },
        ...mapGetters({
            user: "common/getUser",
            pagePrevious: "dashboard/getPagePrevious"
        })
    },
    methods: {
        page_previous() {
            this.$router.push({ name: this.pagePrevious.page });
        },
        handleUserPermissions(role) {
            role.forEach(element => {
                if (Object.values(this.isUserPermission).some(ai => Object.values(element.permissions).includes(ai))) {
                    element.status = Object.values(this.isUserPermission);
                }
                element.children.forEach(x => {
                    if (Object.values(this.isUserPermission).some(ai => Object.values(x.permission_children).includes(ai))) {
                        element.status = Object.values(this.isUserPermission);
                    }
                });
            });
        },
        logout() {
            ApiService.post("logout").then(() => {
                this.$router.replace("login");
                this.$router.replace("login");
            });
        }
    },
};
</script>